import "core-js/modules/es.regexp.exec";import "core-js/modules/es.string.replace";
export default {
  created: function created() {var _this$$route =
    this.$route,params = _this$$route.params,query = _this$$route.query;var
    path = params.path;

    this.$router.replace({ path: '/' + path, query: query });
  },
  render: function render(h) {
    return h(); // avoid warning message
  } };